import React from 'react';
import { Button, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import TextInput from '../../../../formFields/TextInput';
import { useTranslation } from 'react-i18next';
import GroupUserArray from './groupUserArray';
import { useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { createGroup } from '../../../../services/apiServices/groupApiService';
import { apiErrorHandler } from '../../../../services/notificationService';
import { useNavigate } from 'react-router-dom';
import { Workspaces } from '@mui/icons-material';

let GroupCreate = (props) => {
    const { handleSubmit, createGroup } = props;
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const onSubmit = (values) => {
        let groupName = values.groupName;
        let users = values.users.map((user) => {
            return user.email;
        });

        createGroup({
            groupName,
            participants: users,
        })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    navigate('/groups');
                }
            })
            .catch((error) => {
                // console.error(error);
                apiErrorHandler(error);
            });
    };

    useEffect(() => {
        props.initialize({
            users: [{}],
        });
    }, []);

    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ width: '100%', height: '90%' }}
        >
            <Grid item lg={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item lg={10}>
                            <Field
                                name="groupName"
                                type="text"
                                variant="filled"
                                component={TextInput}
                                placeholder={t('group name')}
                                icon={<Workspaces />}
                            />
                        </Grid>
                        <Grid item container lg={12}>
                            <FieldArray
                                name="users"
                                component={GroupUserArray}
                                icon={<PersonIcon />}
                            />
                        </Grid>
                        <Grid item container justifyContent={'center'}>
                            <Button variant="contained" type={'submit'}>
                                {t('create group')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

GroupCreate = reduxForm({
    form: 'group-create-form',
})(GroupCreate);

const mapDispatchToProps = {
    createGroup: createGroup,
};

GroupCreate = connect(null, mapDispatchToProps)(GroupCreate);

export default GroupCreate;
