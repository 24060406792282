import actionTypes from '../types';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To Update the application theme in redux store
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} theme
 * @returns {Object}
 */
export const updateTheme = (theme) => {
    return {
        type: actionTypes.APP.APP_THEME_UPDATE,
        payload: theme,
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To Update the system theme in redux store
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} theme
 * @returns {Object}
 */
export const updateSystemTheme = (theme) => {
    return {
        type: actionTypes.APP.SYSTEM_THEME_UPDATE,
        payload: theme,
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To update the user data in the auth storage
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {{
 *  id:integer,
 *  fullName:string,
 *  email:string
 * }} userData
 */
export const updateAuthUserData = (userData) => {
    return {
        type: actionTypes.APP.UPDATE_AUTH_USER,
        payload: userData,
    };
};
