import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CategoryItem from './categoryItem';
import { Button, Card, CardContent, Divider, Grid, List } from '@mui/material';
import AddSpeedDial from '../../../components/speedDials/AddSpeedDial';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { getCategories } from '../../../services/apiServices/categoriesApiService';
import { updateCategoriesList } from '../../../redux/actions/categoryActions';
import { mapCategoriesWithChild } from '../../../services/categoryService';

let CategoriesList = (props) => {
    const [categories, setCategories] = useState([]);
    const [staticCategory, setStaticCategory] = useState(null);

    useEffect(() => {
        props
            .getCategories()
            .then((response) => {
                let categoriesWithChildCount = mapCategoriesWithChild(
                    response.data.data,
                );
                props.updateCategoriesList(categoriesWithChildCount);
                setCategories(
                    categoriesWithChildCount.filter((category) => {
                        return !category.parentCategoryId;
                    }),
                );
            })
            .catch(() => {
                // console.error('error', error)
            });
    }, []);

    useEffect(() => {
        let categoriesWithChildCount = mapCategoriesWithChild([
            ...props.categories,
        ]);
        setCategories(categoriesWithChildCount);
    }, [props.categories]);

    useEffect(() => {
        handleOnSubClick(staticCategory, true);
    }, [props.categories]);

    const handleOnSubClick = (selectedCategory, sort = false) => {
        let newCategories = props.categories.filter((category) => {
            return (
                category.parentCategoryId === (selectedCategory?.categoryId || null)
            );
        });
        if (sort) {
            newCategories = newCategories.sort((a, b) => {
                return a.categoryName < b.categoryName
                    ? -1
                    : a.categoryName > b.categoryName
                        ? 1
                        : 0;
            });
        }
        setCategories(newCategories);
        // setCategories(newCategories);
        setStaticCategory(selectedCategory);
    };

    return (
        <>
            <AddSpeedDial />
            <Grid
                container
                sx={{ padding: { sm: 0, md: 3, xs: 0, lg: 3 } }}
                spacing={3}
                justifyContent={'center'}
            >
                <Grid item lg={3} sm={12} md={5} xs={12}>
                    <Card sx={{ overflow: 'auto', maxHeight: '85vh' }}>
                        <CardContent>
                            {staticCategory && (
                                <>
                                    <Button
                                        onClick={() => {
                                            return handleOnSubClick(null);
                                        }}
                                    >
                                        <KeyboardArrowLeft /> Go Back{' '}
                                    </Button>
                                    <List>
                                        <CategoryItem
                                            category={staticCategory}
                                            disableNestedSelect={true}
                                        />
                                    </List>
                                    <Divider />
                                </>
                            )}
                            <List>
                                {categories?.map((category, categoryIndex) => {
                                    return (
                                        <CategoryItem
                                            category={category}
                                            onSubClick={handleOnSubClick}
                                            key={`category-item-${categoryIndex}`}
                                        />
                                    );
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                {/*<Grid item lg={9}>*/}
                {/*    <Card sx={{overflow: 'auto', maxHeight: '70vh',}}>*/}
                {/*        <CardContent>*/}
                {/*        </CardContent>*/}
                {/*    </Card>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        categories: state.categoryReducer.categories,
    };
};

const mapDispatchToProps = {
    getCategories: getCategories,
    updateCategoriesList: updateCategoriesList,
};

CategoriesList = connect(mapStateToProps, mapDispatchToProps)(CategoriesList);

export default CategoriesList;
