import React from 'react';
import {
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    Link,
} from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import ApplicationLogo from '../../../assets/icons/applicationLogo';
import TextInput from '../../../formFields/TextInput';
import validationService from '../../../services/validationService';
import { connect } from 'react-redux';
import { login } from '../../../services/apiServices/authApiService';
import { useEffect, useState } from 'react';
import { Email, VpnKey } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CommonLoader from '../../../components/loadings/commonLoader';
import { getUser } from '../../../services/apiServices/userApiService';
import { checkLogin } from '../../../services/authService';
import { apiErrorHandler } from '../../../services/notificationService';
import { updateAuthUserData } from '../../../redux/actions/appActions';

let Login = (props) => {
    const { handleSubmit } = props;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [loginLoader, setLoginLoader] = useState(false);

    // this use effect is to initialize the login form with empty values
    useEffect(() => {
        props.initialize({
            username: '',
            password: '',
        });
        checkLogin(
            () => {
                setLoader(false);
            },
            (userData) => {
                props.updateAuthUserData(userData);
                navigate('/dashboard');
            },
        );
    }, []);

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To handle when user click on login button
   * This method will collect the username and password from form and send it to backend server
   * On success local storage will be updated with required user data and token and user will be
   * redirected to dashboard route
   * -----------------------------------------------------------------------------------------------------------------
   *
   * @param {{username: string, password: string}} param0
   */
    let loginClickHandler = ({ username, password }) => {
        setLoginLoader(true);
        props
            .login(username, password)
            .then((response) => {
                if (response.data.data.user) {
                    props.updateAuthUserData(response.data.data.user);
                    localStorage.setItem(
                        'userData',
                        JSON.stringify(response.data.data.user),
                    );
                }
                localStorage.setItem('authToken', response.data.data.token);
                navigate('/dashboard');
                setLoginLoader(false);
            })
            .catch((error) => {
                apiErrorHandler(error);
                setLoginLoader(false);
            });
    };

    return loader ? (
        <CommonLoader />
    ) : (
        <form onSubmit={handleSubmit(loginClickHandler)}>
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                style={{ marginTop: '10%' }}
                direction={'column'}
            >
                <Grid item>
                    <ApplicationLogo width={'100'} />
                </Grid>
                <Grid
                    item
                    justifyContent={'center'}
                    alignItems={'center'}
                    minWidth={'25%'}
                    margin={'10px'}
                >
                    <Field
                        name="username"
                        placeholder="Email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                        component={TextInput}
                    />
                </Grid>
                <Grid
                    item
                    justifyContent={'center'}
                    alignItems={'center'}
                    minWidth={'25%'}
                    margin={'10px'}
                >
                    <Field
                        name="password"
                        placeholder="Password"
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <VpnKey />
                                </InputAdornment>
                            ),
                        }}
                        component={TextInput}
                    />
                </Grid>
                <Grid item>
                    {loginLoader && <CircularProgress />}
                    {!loginLoader && (
                        <Button type={'submit'} size={'medium'} variant={'contained'}>
              Login
                        </Button>
                    )}
                </Grid>
                <Grid
                    item
                    alignItems={'end'}
                    textAlign={'right'}
                    minWidth={'25%'}
                    margin={'10px'}
                >
                    <Link href={'/signup'}>Create An Account</Link>
                </Grid>
                <Grid item alignItems={'end'} textAlign={'right'} minWidth={'25%'}>
                    <Link href={'/signup'}>Forgot Password</Link>
                </Grid>
            </Grid>
        </form>
    );
};

Login = reduxForm({
    form: 'loginForm',
    validate: validationService.loginFormValidations,
})(Login);

let mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => {
            return dispatch(login(username, password));
        },
        getUser: () => {
            return dispatch(getUser());
        },
        updateAuthUserData: (userData) => {
            return dispatch(updateAuthUserData(userData));
        },
    };
};

Login = connect(null, mapDispatchToProps)(Login);

export default Login;
