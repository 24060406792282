import apiRoutes from '../../config/apiRoutes';
import { deleteCall, get, post } from './baseApiService';

export const getBills = ({ startDate, endDate, groupId }) => {
    return () => {
        let qs = new URLSearchParams({ startDate, endDate, groupId });
        return get(`${apiRoutes.getBills}?${qs}`);
    };
};

export const createBill = (body) => {
    return () => {
        return post(apiRoutes.createBill, body);
    };
};

export const deleteBill = (billId) => {
    return () => {
        return deleteCall(apiRoutes.deleteBill, { billId });
    };
};
