import axios from 'axios';
import { getToken } from '../userService';

const basePath = `${process.env.REACT_APP_API_PROTOCOL || 'https://'}${process.env.REACT_APP_BASE_API_PATH || 'localhost:8000'}`;

let customAxios = axios.create({
    baseURL: `${basePath}/api`,
});

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To call the post api with the provided body data
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} uri
 * @param {Object} body
 * @returns {Promise}
 */
export const post = (uri, body = {}) => {
    let token = getToken();
    return customAxios.post(uri, body, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteCall = (uri, body = {}) => {
    let token = getToken();
    return customAxios.delete(uri, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            ...body,
        },
    });
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To hit the get method api without the token
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} url
 * @returns
 */
export const get = (url) => {
    let token = getToken();
    return customAxios.get(url, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
