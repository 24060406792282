import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import TranslateIcon from '@mui/icons-material/Translate';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuList,
    Tooltip,
    useTheme,
} from '@mui/material';
import { AccountCircle, Logout } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { getUserNameShort } from '../../../services/userService';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../services/apiServices/authApiService';
import { updateTheme } from '../../../redux/actions/appActions';

let UserMenu = (props) => {
    let [userNameShort, setUserNameShort] = useState('');
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(null);
    const [themeMenuOpen, setThemeMenuOpen] = useState(false);
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    let { t } = useTranslation('common');
    let navigate = useNavigate();
    const theme = useTheme();

    // to set the user name in menu button
    useEffect(() => {
        setUserNameShort(getUserNameShort());
        setTheme();
    }, []);

    const setTheme = () => {
        let theme = localStorage.getItem('currentTheme');
        if (theme) {
            props.updateTheme(theme);
        }
    };

    // to handle when the menu open/reopen it will reset the menu
    useEffect(() => {
    // resetting drop down when reopening the component
        if (isUserMenuOpen) {
            setThemeMenuOpen(false);
            setLanguageMenuOpen(false);
        }
    }, [isUserMenuOpen]);

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To open the user menu when user click on menu button
   * -----------------------------------------------------------------------------------------------------------------
   *
   * @param {SyntheticEvent} event
   */
    const handleOpenUserMenu = (event) => {
        setIsUserMenuOpen(event.currentTarget);
    };

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To close the user menu when user click on menu button
   * -----------------------------------------------------------------------------------------------------------------
   */
    const handleCloseUserMenu = () => {
        setIsUserMenuOpen(null);
    };

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To apply the application theme on selecting from user menu
   * This will call the redux method to update the application theme which will further update the
   * theme provider in app.js
   * -----------------------------------------------------------------------------------------------------------------
   *
   * @param {String} themeValue
   */
    const handleThemeUpdate = (themeValue) => {
        return () => {
            localStorage.setItem('currentTheme', themeValue);
            props.updateTheme(themeValue);
        };
    };

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To apply the application language on selecting from user menu
   * This will update the language in the i18next object which will further handle the impact
   * -----------------------------------------------------------------------------------------------------------------
   *
   * @param language
   */
    const handleChangeLanguage = (language) => {
        return () => {
            i18next.changeLanguage(language);
        };
    };

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To handle the log out,
   * user data will be removed from the localStorage and user will be redirect to login page
   * -----------------------------------------------------------------------------------------------------------------
   */
    const handleLogout = () => {
        props
            .logout()
            .then((response) => {
                if (response.data.status) {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('userData');
                    navigate('/login');
                }
            })
            .catch(() => {
                // console.error('error in logout', error);
            });
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} size={'small'} sx={{ p: 0 }}>
                <Avatar
                    sx={{
                        bgcolor: theme.palette.secondary?.main,
                        width: 35,
                        height: 35,
                        lineHeight: 20,
                        fontSize: 20,
                    }}
                >
                    {userNameShort}
                </Avatar>
            </IconButton>

            <Menu
                id="user-menu"
                anchorEl={isUserMenuOpen}
                onClose={handleCloseUserMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                open={Boolean(isUserMenuOpen)}
            >
                <MenuList sx={{ minWidth: '250px' }}>
                    <MenuItem>
                        <ListItemIcon>
                            <AccountCircle fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('account settings')}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            return setThemeMenuOpen(!themeMenuOpen);
                        }}
                    >
                        <ListItemIcon>
                            <ColorLensIcon />
                        </ListItemIcon>
                        <ListItemText
                            id="switch-list-label-bluetooth"
                            primary={t('change theme')}
                        />
                    </MenuItem>

                    <Collapse in={themeMenuOpen}>
                        <MenuItem
                            sx={{ pl: 6 }}
                            onClick={handleThemeUpdate('system')}
                            selected={props.currentTheme === 'system'}
                        >
                            <ListItemIcon>
                                <InvertColorsIcon />
                            </ListItemIcon>
                            <ListItemText>
                                {t('same as system')}: {t(props.systemTheme)}
                            </ListItemText>
                        </MenuItem>

                        <MenuItem
                            sx={{ pl: 6 }}
                            onClick={handleThemeUpdate('dark')}
                            selected={props.currentTheme === 'dark'}
                        >
                            <ListItemIcon>
                                <DarkModeIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t('dark mode')}</ListItemText>
                        </MenuItem>

                        <MenuItem
                            sx={{ pl: 6 }}
                            onClick={handleThemeUpdate('light')}
                            selected={props.currentTheme === 'light'}
                        >
                            <ListItemIcon>
                                <LightModeIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t('light mode')}</ListItemText>
                        </MenuItem>
                    </Collapse>

                    <MenuItem
                        onClick={() => {
                            return setLanguageMenuOpen(!languageMenuOpen);
                        }}
                    >
                        <ListItemIcon>
                            <TranslateIcon />
                        </ListItemIcon>
                        <ListItemText
                            id="switch-list-label-bluetooth"
                            primary={t('change language')}
                        />
                    </MenuItem>

                    <Collapse in={languageMenuOpen}>
                        <MenuItem
                            sx={{ pl: 6 }}
                            onClick={handleChangeLanguage('en')}
                            selected={i18next.language === 'en'}
                        >
                            <ListItemText>English</ListItemText>
                        </MenuItem>

                        <MenuItem
                            sx={{ pl: 6 }}
                            onClick={handleChangeLanguage('hi')}
                            selected={i18next.language === 'hi'}
                        >
                            <ListItemText>हिंदी</ListItemText>
                        </MenuItem>

                        <Tooltip
                            title={t('coming soon')}
                            disabled={true}
                            placement="bottom"
                        >
                            <span>
                                <MenuItem
                                    sx={{ pl: 6 }}
                                    onClick={handleChangeLanguage('fr')}
                                    selected={i18next.language === 'fr'}
                                    disabled={true}
                                >
                                    <ListItemText>Française</ListItemText>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    </Collapse>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('logout')}</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

let mapStateToProps = (state) => {
    return {
        currentTheme: state?.appReducer?.theme?.mode,
        systemTheme: state?.appReducer?.theme?.systemMode,
    };
};

let mapDispatchToProps = {
    updateTheme: updateTheme,
    logout,
};

UserMenu = connect(mapStateToProps, mapDispatchToProps)(UserMenu);

export default UserMenu;
