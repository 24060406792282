import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { addGroupUser } from '../../../../../services/apiServices/groupApiService';
import { connect } from 'react-redux';
import { apiErrorHandler } from '../../../../../services/notificationService';
import { useParams } from 'react-router-dom/dist';
import { addUserToCurrentGroup } from '../../../../../redux/actions/groupActions';

let AddUserDialog = (props) => {
    const { open } = props;
    const [email, setEmail] = useState(null);
    const { groupId } = useParams();
    const { t } = useTranslation('common');

    let handleClose = () => {
        setEmail(null);
        props.handleClose();
    };
    const save = () => {
        props
            .addGroupUser({ email, groupId })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    props.addUserToCurrentGroup(res.data.data);
                    handleClose();
                }
            })
            .catch(apiErrorHandler);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('add user')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('add user to your group')}</DialogContentText>
                    <TextField
                        margin="dense"
                        id="email"
                        label={t('email address')}
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={handleEmailChange}
                        value={email}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={save}>{t('save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        currentGroup: state.groupReducer.currentGroup,
    };
};

const mapDispatchToProps = {
    addGroupUser: addGroupUser,
    addUserToCurrentGroup: addUserToCurrentGroup,
};
AddUserDialog = connect(mapStateToProps, mapDispatchToProps)(AddUserDialog);

export default AddUserDialog;
