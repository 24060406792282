import actionTypes from '../types';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To Update the categories list for the current user
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param categories
 * @returns {Object}
 */
export const updateCategoriesList = (categories) => {
    return {
        type: actionTypes.CATEGORY.UPDATE_CATEGORIES,
        payload: categories,
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description to add the category to the current list,
 * this will check if the category have parent id then it will add it under the parent category list
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param category
 * @returns {{payload, type: string}}
 */
export const pushCategory = (category) => {
    return {
        type: actionTypes.CATEGORY.PUSH_CATEGORY,
        payload: category,
    };
};
