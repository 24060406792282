import React from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBills } from '../../../../../../services/apiServices/billApiService';
import { useSearchParams } from 'react-router-dom/dist';
import MemberRow from './memberRow';
import GroupDateRange from '../groupDateRange';
import CommonLoader from '../../../../../../components/loadings/commonLoader';

let MemberAnalyze = (props) => {
    const { t } = useTranslation('common');
    let [searchParams] = useSearchParams();
    let [group, setGroup] = useState({});
    let [paidByUser, setPaidByUser] = useState([]);
    let [loader, setLoader] = useState(true);
    let { groupId, memberId } = useParams();
    memberId = Number.parseInt(memberId);

    let startDate =
    searchParams.get('startDate') ||
    moment().subtract('1', 'y').format('YYYY-MM-DD');
    let endDate = searchParams.get('endDate') || moment().format('YYYY-MM-DD');

    useEffect(() => {
        props
            .getBills({ startDate, endDate, groupId })
            .then((response) => {
                let bills = response.data.data;
                let group = response.data.group;
                setGroup(group);
                parseBill(bills);
                setLoader(false);
            })
            .catch(() => {
                return setLoader(false);
            });
    }, []);

    const parseBill = (bills) => {
        let result = {
            paidByUser: [],
            involved: [],
        };

        bills.forEach((bill) => {
            if (bill.paidBy.memberId === memberId) {
                result.paidByUser.push(bill);
            }
            bill.participants.forEach((participant) => {
                if (participant.memberId === memberId) {
                    result.involved.push(bill);
                }
            });
        });

        setPaidByUser(result.paidByUser);
    };

    if (loader) {
        return (
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ height: '85vh' }}
            >
                <Grid item lg={3}>
                    <CommonLoader />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} justifyContent={'center'} sx={{ mt: 2 }}>
            <Grid item lg={11}>
                <Paper elevation={3} sx={{ padding: 3 }}>
                    <Grid container>
                        <Grid container item direction={'column'} lg={2}>
                            <Grid item>
                                <Typography
                                    color={'text.secondary'}
                                    variant={'subtitle2'}
                                    sx={{ pl: 1 }}
                                >
                                    {t('group name')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" sx={{ pl: 1 }}>
                                    {group?.groupName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container lg={10}>
                            <Grid item lg={12}>
                                <GroupDateRange onSubmit={() => {}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item lg={11}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Bill Name</TableCell>
                                <TableCell>Bill Amount</TableCell>
                                <TableCell>Per person contribution</TableCell>
                                <TableCell>Participants</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paidByUser.map((bill) => {
                                return <MemberRow bill={bill} key={`paid-by-${bill.billId}`} />;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = {
    getBills: getBills,
};

// GroupAnalyser = connect(null, mapDispatchToProps)(GroupAnalyser);

// GroupAnalyser = reduxForm({
//     form: "groupAnalyser"
// })(GroupAnalyser);

MemberAnalyze = connect(null, mapDispatchToProps)(MemberAnalyze);

export default MemberAnalyze;
