import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Fragment } from 'react';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

let MemberRow = (props) => {
    const { bill } = props;
    // let [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow
                key={bill.billId}
                hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    {/*<IconButton*/}
                    {/*    aria-label="expand row"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => setOpen(!open)}*/}
                    {/*>*/}
                    {/* {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />} */}
                    {/*</IconButton>*/}
                </TableCell>
                <TableCell component="th" scope="row">
                    {bill.title}
                </TableCell>
                <TableCell component="th" scope="row">
                    {bill.amount}
                </TableCell>
                <TableCell component="th" scope="row">
                    {Math.round(bill.amount / bill.participants.length)}
                </TableCell>
                <TableCell component="th" scope="row">
                    {bill.participants.length}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                            <Typography variant="h6" gutterBottom component="div">
                Participants
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Participant Name</TableCell>
                                        <TableCell>Contribution</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bill.participants.map((participant) => {
                                        return (
                                            <TableRow key={participant.memberId}>
                                                <TableCell component="th" scope="row">
                                                    {participant.name}
                                                </TableCell>
                                                <TableCell>
                                                    {bill.amount / bill.participants.length}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

export default MemberRow;
