import { get } from './baseApiService';
import apiRoutes from '../../config/apiRoutes';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description TO get the transactions data from api
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} username
 * @param {String} password
 * @returns {Promise}
 */
export const getTransactions = () => {
    return () => {
        return get(apiRoutes.getTransactions);
    };
};
