import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To provide the field component for the auto complete in mui provided component
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {{
 *  options: {label: String, id: String, Component?: import('react').ReactComponentElement}[],
 * }} field
 * @returns
 */
let AutoCompleteSelect = (field) => {
    const { input, ...props } = field;

    return (
        <Autocomplete
            options={props.options}
            multiple={props.multiple || false}
            renderInput={(params) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        {React.cloneElement(props.icon, { sx: { mr: 1, my: 0.5 } })}
                        <TextField
                            {...params}
                            label={props.placeholder}
                            variant="standard"
                            margin="dense"
                        />
                    </Box>
                );
            }}
            onChange={(event, value) => {
                input.onChange(value);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            value={input.value}
            fullWidth={true}
            // variant={"standard"}
            // multiple={props.multiple || false}
            // onChange={(event, value) => {
            //     value = value ? JSON.parse(JSON.stringify(value)) : null;
            //     input.onChange(value);
            // }}
            // isOptionEqualToValue={(option, value) => {
            //     return option.id === value.id;
            // }}
            // filterOptions={props.filterOptions || input.filterOptions}
            // onInputChange={props.onInputChange || input.onInputChange}
            // renderOption={props.renderOption || input.renderOption}
            // renderInput={(params) => {
            //     return (
            //         props.icon
            //             ? <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
            //                 {
            //                     React.cloneElement(
            //                         props.icon,
            //                         {sx: { mr: 1, my: 0.5}}
            //                     )
            //                 }
            //                 <TextField
            //                     {...params}
            //                     id="input-with-sx"
            //                     label={props.placeholder}
            //                     variant="standard"
            //                     margin='dense'
            //                 />
            //             </Box>
            //             : <TextField id="input-with-sx" label="With sx" variant="standard"/>
            //     )
            // }}
        />
    );
};

export default AutoCompleteSelect;
