import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './appReducer';
import { reducer as formReducer } from 'redux-form';
import categoryReducer from './categoryReducer';
import groupReducer from './groupReducer';

export default combineReducers({
    appReducer,
    categoryReducer,
    groupReducer,
    form: formReducer,
});
