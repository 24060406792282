import React from 'react';
import { Delete } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deleteGroup } from '../../../../services/apiServices/groupApiService';
import { connect } from 'react-redux';
import { apiErrorHandler } from '../../../../services/notificationService';
import { useState } from 'react';

let DeleteGroupDialog = ({
    open,
    handleClose,
    groupName,
    onSubmit,
    groupId,
    ...props
}) => {
    const { t } = useTranslation('common');
    const [groupNameInput, setGroupNameInput] = useState();
    const [errorMessage, setErrorMessage] = useState(null);

    const handleTextInput = (event) => {
        setGroupNameInput(event.target.value);
    };

    const processDelete = () => {
        if (!groupNameInput) {
            setErrorMessage(t('required field'));
        } else if (groupNameInput !== groupName) {
            setErrorMessage(t('group name not matching'));
        } else {
            setErrorMessage(null);
            props
                .deleteGroup(groupId)
                .then(() => {
                    onSubmit();
                })
                .catch((e) => {
                    apiErrorHandler(e);
                });
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('group delete confirm title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography color={'text.secondary'} sx={{ mb: 2 }}>
                            {t('group delete content_line1')}
                        </Typography>
                        <Typography variant={'subtitle2'}>
                            <em>
                                {t('group delete content_line2')}{' '}
                                <strong>
                                    {'"'}
                                    {groupName}
                                    {'"'}
                                </strong>{' '}
                                {t('group delete content_line3')}
                            </em>
                        </Typography>
                        <TextField
                            onChange={handleTextInput}
                            fullWidth
                            size={'small'}
                            variant="outlined"
                            error={errorMessage}
                            helperText={errorMessage}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mb: 2 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={processDelete}
                        color={'error'}
                        variant={'contained'}
                        sx={{ mr: 2 }}
                    >
                        <Delete sx={{ mr: 1 }} /> Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapDispatchToProps = {
    deleteGroup: deleteGroup,
};

DeleteGroupDialog = connect(null, mapDispatchToProps)(DeleteGroupDialog);

export default DeleteGroupDialog;
