import React from 'react';
import { AppBar, Button, Container, Grid, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import ApplicationLogo from '../../../assets/icons/applicationLogo';

import { TabContext, TabList } from '@mui/lab';

import i18next from 'i18next';
import { connect } from 'react-redux';
import UserMenu from './userMenu';
import HeaderMenu from './headerMenu';

import { useNavigate } from 'react-router-dom';
import { updateTheme } from '../../../redux/actions/appActions';
import { APP_TABS } from '../../../constants';

let AuthHeader = (props) => {
    let { t } = useTranslation('common');
    let navigate = useNavigate();

    const { tabValue } = props;

    const handleTabChange = (event, newValue) => {
        let path = '';
        switch (newValue) {
        case APP_TABS.CATEGORIES:
            path = 'categories';
            break;
        case APP_TABS.TRANSACTIONS:
            path = 'transactions';
            break;
        case APP_TABS.GROUPS:
            path = 'groups';
            break;
        default:
            path = '/';
        }
        navigate(`/${path}`);
    };

    return (
        <AppBar position="static">
            <Container maxWidth={'100%'}>
                <Toolbar disableGutters sx={{ minHeight: '50px !important' }}>
                    <Grid sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <ApplicationLogo width={'40px'} fill={'white'} />
                    </Grid>
                    <Typography
                        variant="h6"
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            marginLeft: '10px',
                            display: { xs: 'none', md: 'flex' },
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {t('APP NAME')}
                    </Typography>

                    <TabContext value={tabValue}>
                        <Box>
                            <TabList onChange={handleTabChange} aria-label="Tabs list">
                                <Tab label={t('transactions')} value={APP_TABS.TRANSACTIONS} />
                                <Tab label={t('categories')} value={APP_TABS.CATEGORIES} />
                                <Tab label={t('groups')} value={APP_TABS.GROUPS} />
                            </TabList>
                        </Box>
                        {/* <TabPanel value={tabValue}>{children}</TabPanel> */}
                    </TabContext>

                    <HeaderMenu />

                    <Typography
                        sx={{
                            marginLeft: '20px',
                            color: 'inherit',
                            textDecoration: 'none',
                            flexGrow: 1,
                        }}
                    />

                    {i18next.language !== 'en' && (
                        <Box
                            sx={{ display: { xs: 'flex', md: 'block' } }}
                            style={{ marginRight: '20px' }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    return i18next.changeLanguage('en');
                                }}
                                color={'inherit'}
                            >
                Change Language To English
                            </Button>
                        </Box>
                    )}
                    <UserMenu />
                </Toolbar>
            </Container>
        </AppBar>
    );
};

let mapDispatchToProps = {
    updateTheme: updateTheme,
};

AuthHeader = connect(null, mapDispatchToProps)(AuthHeader);

export default AuthHeader;
