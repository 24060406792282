import React, { useEffect, useState } from 'react';
import { Dialog, Grid } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../../../formFields/TextInput';
import DateInput from '../../../../../formFields/DateInput';
import AutoCompleteSelect from '../../../../../formFields/AutoCompleteSelect';

import {
    Title as TitleIcon,
    Description as DescriptionIcon,
    CalendarMonth as CalendarMonthIcon,
    Person as PersonIcon,
    Groups as GroupsIcon,
    CurrencyRupee as CurrencyRupeeIcon,
} from '@mui/icons-material';

import { connect } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { createBill } from '../../../../../services/apiServices/billApiService';
import { apiErrorHandler } from '../../../../../services/notificationService';
import { addGroupBills } from '../../../../../redux/actions/groupActions';

let AddBillDialog = (props) => {
    const { open, handleClose, handleSubmit, initialize } = props;
    const { t } = useTranslation('common');
    const [groupUsers, setGroupUsers] = useState([]);
    const { groupId } = useParams();

    useEffect(() => {
        try {
            const users = [
                ...props.currentGroup.users.map((user) => {
                    return {
                        id: user.userId,
                        label: user.userName
                            ? `${user.userName} (${user.email})`
                            : user.email,
                        email: user.email,
                    };
                }),
            ];

            setGroupUsers(users);
            initialize({
                billName: null,
                billDescription: null,
                billAmount: null,
                billDate: new moment().valueOf(),
                billPaidBy: null,
                billParticipants: [],
            });
        } catch (e) {
            // console.error(e);
        }
    }, [props.currentGroup]);

    const save = (values) => {
        let billDate = moment(values.billDate);
        let apiPayload = {
            title: values.billName,
            description: values.billDescription,
            amount: values.billAmount,
            date: billDate.format('YYYY-MM-DD'),
            paidBy: values.billPaidBy.email,
            groupId: groupId,
            users: values.billParticipants.map((p) => {
                return p.email;
            }),
        };
        props
            .createBill(apiPayload)
            .then((res) => {
                props.addGroupBills(res.data.data);
                handleClose();
            })
            .catch((e) => {
                apiErrorHandler(e);
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(save)}>
                <DialogTitle>{t('add bill')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('add user to your bill')}</DialogContentText>
                    <Grid container>
                        <Grid item lg={12}>
                            <Field
                                name="billName"
                                type="text"
                                component={TextInput}
                                placeholder={t('bill name')}
                                variant="standard"
                                icon={<TitleIcon />}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Field
                                name="billDescription"
                                component={TextInput}
                                variant="standard"
                                placeholder={t('description')}
                                icon={<DescriptionIcon />}
                                multiline={true}
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Field
                                name="billAmount"
                                component={TextInput}
                                type="number"
                                variant="standard"
                                placeholder={t('bill amount')}
                                icon={<CurrencyRupeeIcon />}
                                multiline={true}
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Field
                                name="billDate"
                                component={DateInput}
                                placeholder={t('date')}
                                icon={<CalendarMonthIcon />}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Field
                                name="billPaidBy"
                                options={groupUsers}
                                component={AutoCompleteSelect}
                                placeholder={t('paid by')}
                                variant="outlined"
                                icon={<PersonIcon />}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Field
                                name="billParticipants"
                                options={groupUsers}
                                multiple={true}
                                component={AutoCompleteSelect}
                                variant="outlined"
                                placeholder={t('participants')}
                                icon={<GroupsIcon />}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button type={'submit'}>{t('save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        currentGroup: state.groupReducer.currentGroup,
    };
};

const mapDispatchToProps = {
    createBill: createBill,
    addGroupBills: addGroupBills,
};

AddBillDialog = connect(mapStateToProps, mapDispatchToProps)(AddBillDialog);

AddBillDialog = reduxForm({
    form: 'createBillForm',
})(AddBillDialog);

export default AddBillDialog;
