import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/dist';

let GroupList = (props) => {
    const { groupList } = props;
    const { t } = useTranslation('common');
    const [currentGroupOpen, setCurrentGroupOpen] = useState(null);

    return (
        <>
            {groupList.map((group) => {
                return (
                    <Accordion
                        key={`group-${group.groupId}`}
                        expanded={currentGroupOpen === group.groupId}
                        onChange={() => {
                            return setCurrentGroupOpen(
                                currentGroupOpen === group.groupId ? null : group.groupId,
                            );
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {group.groupName}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                {t('there are total users').replace(
                                    ':users',
                                    group.users.length,
                                )}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item lg={12} container justifyContent={'end'}>
                                    <Button component={Link} to={`/group/${group.groupId}`}>
                                        {t('open group')}
                                    </Button>
                                </Grid>
                                <Grid item lg={12}>
                                    <List>
                                        {group.users.map((user) => {
                                            return (
                                                <>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar
                                                                // sx={{ width: 30, height: 30 }}
                                                            >
                                                                {(
                                                                    user.userName?.[0] || user.email[0]
                                                                ).toUpperCase()}
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={user.userName || user.email}
                                                        />
                                                    </ListItem>
                                                </>
                                            );
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        groupList: state.groupReducer.groups,
    };
};

GroupList = connect(mapStateToProps)(GroupList);

export default GroupList;
