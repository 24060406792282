import { Box, LinearProgress } from '@mui/material';
import React from 'react';

let CommonLoader = () => {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    );
};

export default CommonLoader;
