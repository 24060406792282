import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const NothingHere = (props) => {
    const { t } = useTranslation('common');

    return (
        <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ height: '80%' }}
        >
            <Grid item>
                <Typography variant="h3" color="text.secondary">
                    {t('nothing_here')}
                </Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }}>
                <Button component={RouterLink} to={props.to} variant="contained">
                    {t('add item')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default NothingHere;
