import actionTypes from '../types';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To Update the current group list in app
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param categories
 * @returns {Object}
 */
export const updateGroupList = (groups) => {
    return {
        type: actionTypes.GROUP.UPDATE_GROUP_LIST,
        payload: groups,
    };
};

export const updateCurrentGroup = (group) => {
    return {
        type: actionTypes.GROUP.UPDATE_CURRENT_GROUP,
        payload: group,
    };
};

export const addUserToCurrentGroup = (userId) => {
    return {
        type: actionTypes.GROUP.ADD_USER_CURRENT_GROUP,
        payload: userId,
    };
};

export const removeUserToCurrentGroup = (userId) => {
    return {
        type: actionTypes.GROUP.REMOVE_USER_CURRENT_GROUP,
        payload: userId,
    };
};

export const updateGroupBills = (bills) => {
    return {
        type: actionTypes.GROUP.UPDATE_GROUP_BILLS,
        payload: bills,
    };
};

export const removeGroupBills = (bill) => {
    return {
        type: actionTypes.GROUP.REMOVE_GROUP_BILLS,
        payload: bill,
    };
};

export const addGroupBills = (bill) => {
    return {
        type: actionTypes.GROUP.ADD_GROUP_BILLS,
        payload: bill,
    };
};
