import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkLogin } from '../../services/authService';
import AuthHeader from '../../components/headers/authHeader/authHeader';
import CommonLoader from '../../components/loadings/commonLoader';
import { updateAuthUserData } from '../../redux/actions/appActions';

let ProtectedRoutes = (props) => {
    const { tabValue } = props;

    let navigate = useNavigate();
    let [loader, setLoader] = useState(true);

    // to validate if user is logged in or not
    useEffect(() => {
        checkLogin(
            () => {
                navigate('/login');
            },
            (userData) => {
                props.updateAuthUserData(userData);
                setLoader(false);
            },
        );
    }, []);

    if (loader) {
        return (
            <Grid
                container
                sx={{ height: '100%' }}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid item lg={4}>
                    <CommonLoader />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <AuthHeader tabValue={tabValue} />
            {props.children}
        </>
    );
};

const mapDispatchToProps = {
    updateAuthUserData: updateAuthUserData,
};

ProtectedRoutes = connect(null, mapDispatchToProps)(ProtectedRoutes);

export default ProtectedRoutes;
