import React from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import moment from 'moment';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBills } from '../../../../../services/apiServices/billApiService';
import { apiErrorHandler } from '../../../../../services/notificationService';
import { calculateSpent } from '../../../../../services/billService';
import Link from '@mui/material/Link';
import AnalyzerDateRange from './groupDateRange';

let GroupAnalyser = (props) => {
    const { t } = useTranslation('common');
    const { groupId } = useParams();
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState();

    const [bills, setBills] = useState({ userSpends: [], meta: {} });

    const fetchAnalyze = (values) => {
        let startDate = moment(values.startDate);
        let endDate = moment(values.endDate);

        let data = {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            groupId,
        };

        setDateRange({
            startDate: data.startDate,
            endDate: data.endDate,
        });

        props
            .getBills(data)
            .then((res) => {
                let result = calculateSpent(res.data.data);
                setBills(result);
            })
            .catch((e) => {
                return apiErrorHandler(e);
            });
    };

    return (
        <>
            <AnalyzerDateRange onSubmit={fetchAnalyze} />

            {bills?.userSpends.length > 0 && (
                <Grid container sx={{ mt: 5 }}>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="left">{t('spent on others')}</TableCell>
                                    <TableCell align="left">{t('spent on self')}</TableCell>
                                    <TableCell align="left">{t('involved in others')}</TableCell>
                                    <TableCell align="left">{t('total involved')}</TableCell>
                                    <TableCell align="left">{t('total paid')}</TableCell>
                                    <TableCell align="left">{t('to pay')}</TableCell>
                                    <TableCell align="left">{t('to receive')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bills.userSpends.map((bill) => {
                                    return (
                                        <TableRow
                                            hover
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            key={`bill-${bill.billId}`}
                                        >
                                            <TableCell align="left">
                                                <Tooltip title={bill.tooltip}>
                                                    <Link
                                                        component={'button'}
                                                        color={'inherit'}
                                                        underline={'hover'}
                                                        onClick={() => {
                                                            navigate({
                                                                pathname: `member/${bill.user.memberId}`,
                                                                search: createSearchParams({
                                                                    ...dateRange,
                                                                }).toString(),
                                                            });
                                                        }}
                                                    >
                                                        {bill.user.name || bill.user.email}
                                                    </Link>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.spentOnOthers)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.spentOnSelf)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.involvedInOthers)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.totalInvolved)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.totalPaid)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.toPay) || '-'}
                                            </TableCell>
                                            <TableCell align="left">
                                                {Math.ceil(bill.toReceive) || '-'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                <TableRow>
                                    <TableCell rowSpan={5} />
                                    <TableCell rowSpan={5} />
                                    <TableCell rowSpan={5} />
                                    <TableCell colSpan={2}>{t('total spent')}</TableCell>
                                    <TableCell align="left">{bills?.meta.totalSpent}</TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                                <TableRow hover>
                                    <TableCell colSpan={2}>{t('total participants')}</TableCell>
                                    <TableCell align="left">{bills?.userSpends.length}</TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                                <TableRow hover>
                                    <TableCell colSpan={2}>
                                        {t('average spent per person')}
                                    </TableCell>
                                    <TableCell align="left">{bills?.meta.averageSpent}</TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                                <TableRow hover>
                                    <TableCell colSpan={3}>
                                        {t('total amount to be paid by everyone')}
                                    </TableCell>
                                    <TableCell align="left">{bills?.meta.totalToPay}</TableCell>
                                    <TableCell />
                                </TableRow>
                                <TableRow hover>
                                    <TableCell colSpan={4}>
                                        {t('total amount to be received by everyone')}
                                    </TableCell>
                                    <TableCell align="left">
                                        {bills?.meta.totalToReceive}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </>
    );
};

const mapDispatchToProps = {
    getBills: getBills,
};
GroupAnalyser = connect(null, mapDispatchToProps)(GroupAnalyser);
export default GroupAnalyser;
