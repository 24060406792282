import actionTypes from '../types';

let initialState = {
    theme: {
        mode: 'light',
        systemMode: null,
    },
    authUser: {},
};

let appReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.APP.APP_THEME_UPDATE:
        state = {
            ...state,
            theme: {
                ...state.theme,
                mode: action.payload,
            },
        };
        break;
    case actionTypes.APP.SYSTEM_THEME_UPDATE:
        state = {
            ...state,
            theme: {
                ...state.theme,
                systemMode: action.payload,
            },
        };
        break;
    case actionTypes.APP.UPDATE_AUTH_USER:
        state = {
            ...state,
            auth: {
                ...state.auth,
                ...action.payload,
            },
        };
        break;
    default:
        return state;
    }
    return state;
};

export default appReducer;
