import apiRoutes from '../../config/apiRoutes';
import { deleteCall, get, post } from './baseApiService';

export const getGroups = () => {
    return () => {
        return get(apiRoutes.getGroups);
    };
};

export const getGroup = (groupId) => {
    return () => {
        return get(`${apiRoutes.getGroup}/${groupId}`);
    };
};

export const createGroup = (body) => {
    return () => {
        return post(apiRoutes.createGroup, body);
    };
};
export const addGroupUser = (body) => {
    return () => {
        return post(apiRoutes.addGroupUser, body);
    };
};

export const removeGroupUser = (body) => {
    return () => {
        return deleteCall(apiRoutes.removeGroupUser, body);
    };
};

export const getGroupDetails = (groupId) => {
    return () => {
        return get(`${apiRoutes.getGroupDetails}/${groupId}`);
    };
};

export const deleteGroup = (groupId) => {
    return () => {
        return deleteCall(apiRoutes.deleteGroup, { group_id: groupId });
    };
};
