import React from 'react';
import { Calculate, Delete, Edit, Receipt } from '@mui/icons-material';
import {
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom/dist';
import DeleteGroupDialog from './deleteGroupDialog';
import { connect } from 'react-redux';

const NAVIGATORS = {
    DASHBOARD: 'dashboard',
    USERS: 'users',
    BILLS: 'bills',
    ANALYSER: 'analyser',
    DELETE_GROUP: 'deleteGroup',
};

let GroupDetailNavigation = (props) => {
    const { currentNavigation } = props;
    const navigate = useNavigate();
    const { groupId } = useParams();
    const [deleteGroupOpen, setDeleteGroupOpen] = useState(false);
    const [canDeleteGroup, setCanDeleteGroup] = useState(false);

    useEffect(() => {
        setCanDeleteGroup(
            props.groupDetails?.createdBy?.userId === props.authUser?.id,
        );
    }, [props.authUser]);

    const changeNavigation = (to) => {
        return () => {
            navigate(`/group/${groupId}/${to}`);
        };
    };

    let { groupDetails } = props;

    /**
   * @type {{
   *  navigateTo: string,
   *  icon: Component,
   *  label: string,
   *  secondary: string,
   *  mode: string
   * }[]}
   */
    const navigationConfig = [
        {
            navigateTo: NAVIGATORS.DASHBOARD,
            icon: <DashboardIcon />,
            label: 'dashboard',
        },
        {
            navigateTo: NAVIGATORS.USERS,
            icon: <GroupIcon />,
            label: 'users',
            secondary: groupDetails.users.length,
        },
        {
            navigateTo: NAVIGATORS.BILLS,
            icon: <Receipt />,
            label: 'bills',
            secondary: groupDetails.billsCount,
        },
        {
            navigateTo: NAVIGATORS.ANALYSER,
            icon: <Calculate />,
            label: 'spent analyser',
        },
    ];

    const { t } = useTranslation('common');

    return (
        <Paper sx={{ position: 'sticky', top: 20 }}>
            <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ pb: 1, pt: 1 }}
            >
                <Grid item lg={8} container direction={'column'}>
                    <Typography
                        color={'text.secondary'}
                        variant={'subtitle2'}
                        sx={{ pl: 1 }}
                    >
                        {t('group name')}
                    </Typography>
                    <Typography variant="h6" sx={{ pl: 1 }}>
                        {groupDetails.groupName}
                    </Typography>
                </Grid>
                <Grid item lg={2} container justifyContent={'end'} sx={{ pr: 1 }}>
                    <IconButton aria-label="edit">
                        <Edit />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <Grid>
                <List dense={true}>
                    {navigationConfig.map((navigation) => {
                        return (
                            <ListItem
                                disablePadding
                                key={`group-navigation-${navigation.navigateTo}`}
                            >
                                <ListItemButton
                                    onClick={changeNavigation(navigation.navigateTo)}
                                    selected={currentNavigation === navigation.navigateTo}
                                >
                                    <ListItemIcon>{navigation.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={t(navigation.label)}
                                        color={'text.secondary'}
                                    />
                                    {navigation.secondary && (
                                        <Typography variant={'subtitle2'} color={'text.secondary'}>
                                            {navigation.secondary}
                                        </Typography>
                                    )}
                                </ListItemButton>
                            </ListItem>
                        );
                    })}

                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => {
                                return setDeleteGroupOpen(true);
                            }}
                            disabled={!canDeleteGroup}
                        >
                            <ListItemIcon>
                                <Delete color={'error'} />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('delete group')}
                                primaryTypographyProps={{
                                    color: 'error.main',
                                    sx: { fontWeight: 'bold' },
                                }}
                            />
                        </ListItemButton>
                        <DeleteGroupDialog
                            open={deleteGroupOpen}
                            handleClose={() => {
                                return setDeleteGroupOpen(false);
                            }}
                            onSubmit={() => {
                                return navigate('/groups');
                            }}
                            groupName={groupDetails.groupName}
                            groupId={groupDetails.groupId}
                        />
                    </ListItem>
                </List>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        authUser: state.appReducer.auth,
    };
};

GroupDetailNavigation = connect(mapStateToProps)(GroupDetailNavigation);

export default GroupDetailNavigation;
