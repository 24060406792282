let commonSnackbarRef;

export const setCommonSnackbarRef = (ref) => {
    commonSnackbarRef = ref;
};

export const apiErrorHandler = (error) => {
    commonSnackbarRef({
        open: true,
        message: error?.response?.data?.message || error?.message || '',
    });
};
