export const calculateSpent = (bills) => {
    let result = {};
    bills.forEach((bill) => {
        prepareUserForSpent(result, bill.paidBy);
        fillTotalPaidBy(result, bill);
        fillSpentOnOthers(result, bill);
        fillSpentOnSelf(result, bill);
        fillInvolvedInOthers(result, bill);
    });

    fillDebt(result);
    return parseResult(result);
};

const prepareUserForSpent = (result, user) => {
    if (!result[user.email]) {
        result[user.email] = {
            user: user,
            tooltip: user.name ? user.email : '',
            totalPaid: 0,
            totalInvolved: 0,
            spentOnOthers: 0,
            spentOnSelf: 0,
            involvedInOthers: 0,
            toPay: 0,
            toReceive: 0,
        };
    }
};

const fillTotalPaidBy = (result, bill) => {
    result[bill.paidBy.email].totalPaid += bill.amount;
};

const fillSpentOnOthers = (result, bill) => {
    let spentOnOthers = bill.amount;
    bill.participants.forEach((participant) => {
    // if bill paid by user is involved in participant remove it from spent on others.
        if (participant.email === bill.paidBy.email) {
            spentOnOthers -= bill.amount / bill.participants.length;
        }
    });
    result[bill.paidBy.email].spentOnOthers += spentOnOthers;
};

const fillSpentOnSelf = (result, bill) => {
    let spentOnSelf = 0;
    bill.participants.forEach((participant) => {
        if (bill.paidBy.email === participant.email) {
            spentOnSelf += bill.amount / bill.participants.length;
        }
    });

    result[bill.paidBy.email].spentOnSelf += spentOnSelf;
};

const fillInvolvedInOthers = (result, bill) => {
    bill.participants.forEach((participant) => {
        prepareUserForSpent(result, participant);
        // if bill participant is same as bill paid by then the user is not involved with self pa
        if (participant.email === bill.paidBy.email) {
            return;
        } else {
            result[participant.email].involvedInOthers +=
        bill.amount / bill.participants.length;
        }
    });
};

const fillDebt = (result) => {
    for (let email in result) {
        result[email].totalInvolved =
      result[email].involvedInOthers + result[email].spentOnSelf;
        if (result[email].spentOnOthers > result[email].involvedInOthers) {
            // user has spent more than involved, then user should get the money
            result[email].toReceive =
        result[email].spentOnOthers - result[email].involvedInOthers;
        } else {
            // user has involved more than spent on others then user have to pay the money
            result[email].toPay =
        result[email].involvedInOthers - result[email].spentOnOthers;
        }
    }
};

const parseResult = (result) => {
    let newResult = [];
    let totalSpent = 0;
    let averageSpent = 0;
    let totalParticipant = Object.keys(result).length;
    let totalToPay = 0;
    let totalToReceive = 0;
    for (let email in result) {
        newResult.push({
            email: email,
            ...result[email],
        });
        totalSpent += result[email].totalPaid;
        totalToPay += result[email].toPay;
        totalToReceive += result[email].toReceive;
    }
    averageSpent = Math.ceil(totalSpent / (totalParticipant || 1));

    newResult = newResult.sort((a, b) => {
        if (a.toReceive > b.toReceive) {
            return -1;
        } else if (a.toReceive < b.toReceive) {
            return 1;
        } else {
            // If the toPay properties are equal, sort by the colB property.
            return a.toPay > b.toPay ? -1 : 1;
        }
    });

    return {
        userSpends: newResult,
        meta: {
            totalSpent,
            averageSpent,
            totalToPay: Math.ceil(totalToPay),
            totalToReceive: Math.ceil(totalToReceive),
        },
    };
};
