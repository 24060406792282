import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

let TextInput = (field) => {
    const { input, meta, ...props } = field;
    if (props.icon) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                {React.cloneElement(props.icon, {
                    sx: {
                        mr: 1,
                        my: 0.5,
                    },
                })}
                <TextField
                    {...input}
                    size={'small'}
                    variant={props.variant || 'standard'}
                    fullWidth
                    margin="dense"
                    label={props.placeholder || null}
                    type={props.type || 'text'}
                    InputProps={props.InputProps}
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                    multiline={props.multiline || false}
                    maxRows={(props.multiline && props.maxRows) || 1}
                />
            </Box>
        );
    }

    return (
        <TextField
            size={'small'}
            variant="standard"
            fullWidth
            label={props.placeholder || null}
            type={props.type || 'text'}
            InputProps={props.InputProps}
            error={meta.touched && meta.error}
            helperText={meta.touched && meta.error}
            {...input}
        />
    );
};

export default TextInput;
