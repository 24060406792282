const apiRoutes = {
    login: '/login',
    signup: '/signup',
    logout: '/logout',

    getUser: '/user/auth',
    searchUser: '/user/search',

    getTransactions: '/transactions',

    getCategories: '/categories',
    createCategory: '/categories',

    getBills: '/bills',
    createBill: '/bills',
    deleteBill: '/bills',

    getGroups: '/groups',
    getGroup: '/groups',
    createGroup: '/groups',
    deleteGroup: '/groups',
    addGroupUser: '/groups/user',
    removeGroupUser: '/groups/user',
    getGroupDetails: '/groups/details',
};

export default apiRoutes;
