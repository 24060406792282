import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Grid, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import ApplicationLogo from '../../../assets/icons/applicationLogo';
import { useTranslation } from 'react-i18next';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CategoryIcon from '@mui/icons-material/Category';
import { useNavigate } from 'react-router-dom';

let HeaderMenu = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    let { t } = useTranslation('common');
    let navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    <MenuItem
                        key={'transaction'}
                        onClick={() => {
                            return navigate('/transactions');
                        }}
                    >
                        <ReceiptLongIcon sx={{ mr: 1 }} />
                        <Typography textAlign="center">{t('transactions')}</Typography>
                    </MenuItem>

                    <MenuItem
                        key={'category'}
                        onClick={() => {
                            return navigate('/categories');
                        }}
                    >
                        <CategoryIcon sx={{ mr: 1 }} />
                        <Typography textAlign="center">{t('categories')}</Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <Grid
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    ml: 1,
                    mr: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ApplicationLogo width="15%" fill="white" />
            </Grid>
        </>
    );
};

export default HeaderMenu;
