import Box from '@mui/material/Box';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import moment from 'moment';

let DateInput = (field) => {
    const { input, meta, ...props } = field;

    if (props.icon) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                {React.cloneElement(props.icon, {
                    sx: {
                        mr: 1,
                        my: 1,
                    },
                })}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        // {...input}
                        // {...props}
                        value={moment(input.value)}
                        onChange={(e) => {
                            return input.onChange(e.valueOf());
                        }}
                        label={props.placeholder || null}
                        variant={'standard'}
                        fullWidth
                        slotProps={{
                            textField: {
                                margin: 'dense',
                                variant: 'standard',
                                size: 'small',
                                fullWidth: true,
                                type: props.type || 'text',
                                InputProps: props.InputProps,
                                error: meta.touched && meta.error,
                                helperText: meta.touched && meta.error,
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker label={props.placeholder || null} />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DateInput;
