import React, { useState } from 'react';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Snackbar,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddUserDialog from './addUserDialog/addUserDialog.js';
import { Close, Delete } from '@mui/icons-material';
import { connect } from 'react-redux';
import { removeGroupUser } from '../../../../services/apiServices/groupApiService';
import { useParams } from 'react-router-dom/dist';
import { removeUserToCurrentGroup } from '../../../../redux/actions/groupActions';
import { apiErrorHandler } from '../../../../services/notificationService';
import { getUserData } from '../../../../services/userService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

let GroupUserList = (props) => {
    const { groupUsers } = props;
    const { groupId } = useParams();
    const { t } = useTranslation('common');
    const [openAddUser, setOpenAddUser] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    let currentUser = getUserData();

    const triggerAddUserDialog = (value) => {
        return () => {
            setOpenAddUser(value);
        };
    };

    const deleteUser = (userId) => {
        return () => {
            if (currentUser && currentUser.id !== userId) {
                props
                    .removeGroupUser({
                        userId,
                        groupId,
                    })
                    .then((res) => {
                        if (res.status === 200 || res.status === 201) {
                            props.removeUserToCurrentGroup(userId);
                        }
                    })
                    .catch(apiErrorHandler);
            }
        };
    };

    const copyEmailToClipboard = (email) => {
        return () => {
            navigator.clipboard.writeText(email);
            setOpenSnackbar(true);
        };
    };

    const handleClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                handleClose={handleClose}
                autoHideDuration={2000}
                onClose={handleClose}
                message={t('copied to clipboard')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
            <Grid container justifyContent={'space-between'}>
                <Typography variant="h5">{t('users')}</Typography>
                <Button variant="contained" onClick={triggerAddUserDialog(true)}>
                    {t('add user')}
                </Button>
                <AddUserDialog
                    open={openAddUser}
                    handleClose={triggerAddUserDialog(false)}
                />
            </Grid>
            <List dense>
                {groupUsers.map((user) => {
                    return (
                        <ListItem sx={{ pl: 0, pr: 0 }} key={`group-user-${user.userId}`}>
                            <ListItemAvatar>
                                <Avatar>
                                    {(user.userName?.[0] || user.email[0]).toUpperCase()}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography variant="body2" component={'p'}>
                                        {user.userName || user.email}
                                        {!user.userName && (
                                            <Typography
                                                variant="body2"
                                                component={'span'}
                                                color={'text.secondary'}
                                            >
                                                <Button
                                                    size={'small'}
                                                    sx={{ p: 0, minWidth: 'auto', ml: 1 }}
                                                    color="inherit"
                                                    onClick={copyEmailToClipboard(user.email)}
                                                >
                                                    <ContentCopyIcon fontSize="inherit" />
                                                </Button>
                                            </Typography>
                                        )}
                                    </Typography>
                                }
                                secondary={
                                    user.userName ? (
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                            component={'p'}
                                        >
                                            {user.email}
                                            <Button
                                                size={'small'}
                                                sx={{ p: 0, minWidth: 'auto', ml: 1 }}
                                                color="inherit"
                                                onClick={copyEmailToClipboard(user.email)}
                                            >
                                                <ContentCopyIcon fontSize="inherit" />
                                            </Button>
                                        </Typography>
                                    ) : (
                                        ''
                                    )
                                }
                            />
                            {currentUser?.id !== user.userId && (
                                <IconButton onClick={deleteUser(user.userId)}>
                                    <Delete />
                                </IconButton>
                            )}
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};

const mapDispatchToProps = {
    // API
    removeGroupUser: removeGroupUser,

    // STATE
    removeUserToCurrentGroup: removeUserToCurrentGroup,
};

export default connect(null, mapDispatchToProps)(GroupUserList);
