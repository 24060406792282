import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { setCommonSnackbarRef } from '../../services/notificationService';

const CommonSnackbar = () => {
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        mesasge: '',
    });

    let ref = useCallback((snackbarState) => {
        setSnackbarState(snackbarState);
    }, []);

    useEffect(() => {
        setCommonSnackbarRef(ref);
    }, []);

    return (
        <Snackbar
            open={snackbarState.open}
            onClose={() => {
                return setSnackbarState({ open: false, message: '' });
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={3000}
        >
            <Alert severity="error" sx={{ width: '100%' }}>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    );
};

export default CommonSnackbar;
