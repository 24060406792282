import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getGroups } from '../../../services/apiServices/groupApiService';
import { connect } from 'react-redux';
import NothingHere from '../../../components/nothingHere/nothingHere';
import GroupList from '../groups/groupList/groupList';
import { updateGroupList } from '../../../redux/actions/groupActions';
import CommonLoader from '../../../components/loadings/commonLoader';

let TallyDashboard = (props) => {
    const { t } = useTranslation('common');

    const [groupsList, setGroupsList] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        props
            .getGroups()
            .then((res) => {
                if (res.data.data) {
                    props.updateGroupList(res.data.data);
                    setGroupsList(res.data.data);
                    setLoader(false);
                }
            })
            .catch();
    }, []);

    if (loader) {
        return (
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ height: '80vh' }}
            >
                <Grid item lg={3}>
                    <CommonLoader />
                </Grid>
            </Grid>
        );
    }

    if (!groupsList.length) {
        return <NothingHere to={'group/create'} />;
    }

    return (
        <Grid container justifyContent={'center'} sx={{ mt: 2 }} spacing={2}>
            <Grid item lg={6} container justifyContent={'end'}>
                <Button component={RouterLink} to={'group/create'} variant="contained">
                    {t('add')} {t('group')}
                </Button>
            </Grid>
            <Grid item lg={12} />
            <Grid item lg={6}>
                <GroupList />
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = {
    // API
    getGroups: getGroups,

    // State Action
    updateGroupList: updateGroupList,
};

export default connect(null, mapDispatchToProps)(TallyDashboard);
