import { getUser } from './apiServices/userApiService';
import { getToken, removeToken } from './userService';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To check if user is logged in or not
 * and if token is presented, token will be validated with backend server also
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {Function} onLoginCheckFail
 * @param {Function} onLoginCheckSuccess
 */
export const checkLogin = (
    onLoginCheckFail = null,
    onLoginCheckSuccess = null,
) => {
    let token = getToken();
    if (token) {
        getUser()()
            .then((response) => {
                localStorage.setItem('userData', JSON.stringify(response.data.data));
                onLoginCheckSuccess && onLoginCheckSuccess(response.data.data);
            })
            .catch((error) => {
                removeToken();
                onLoginCheckFail && onLoginCheckFail(error);
            });
    } else {
        onLoginCheckFail && onLoginCheckFail();
    }
};
