import actionTypes from '../types';

let initialState = {
    categories: [],
};

let categoryReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.CATEGORY.UPDATE_CATEGORIES:
        state = {
            ...state,
            categories: action.payload,
        };
        break;
    case actionTypes.CATEGORY.PUSH_CATEGORY: {
        let categories = [...state.categories];
        state = {
            ...state,
            categories: pushCategory(categories, action.payload),
        };
        break;
    }
    default:
        return state;
    }
    return state;
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To push the one category with respect to parent id passed
 * if there is parent id it will search for the parent category and add it in parent category child list
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param categories
 * @param targetCategory
 * @returns {*}
 */
const pushCategory = (categories, targetCategory) => {
    let searchResult = false;
    categories = categories.map((category) => {
        if (category.categoryId === targetCategory.categoryId) {
            searchResult = true;
            return {
                ...category,
                ...targetCategory,
            };
        }
        return category;
    });
    if (!searchResult) {
        categories.push(targetCategory);
    }
    return categories;
};

export default categoryReducer;
