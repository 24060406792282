import React, { useEffect, useMemo, useState } from 'react';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import { Category } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../formFields/TextInput';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import validationService from '../../../services/validationService';
import AutoCompleteSelect from '../../../formFields/AutoCompleteSelect';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { connect } from 'react-redux';
import { createCategories } from '../../../services/apiServices/categoriesApiService';
import { pushCategory } from '../../../redux/actions/categoryActions';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import _ from 'lodash';
import ImageIcon from '@mui/icons-material/Image';

const allIcons = require('@mui/icons-material');

let icons = {
    outlined: [],
    filled: [],
    rounded: [],
    sharp: [],
    twotone: [],
    normal: [],
};

for (let icon in allIcons) {
    let iconType = null;
    const keys = ['Outlined', 'Filled', 'Rounded', 'Sharp', 'TwoTone'];
    for (let key of keys) {
        if (icon.includes(key)) {
            iconType = key.toLowerCase();
            break;
        }
    }
    iconType = iconType || 'normal';
    icons[iconType].push({
        label: icon.replace(/([A-Z])/g, ' $1').trim(),
        value: icon,
        Component: allIcons[icon],
    });
}

let CategoryAddDialog = (props) => {
    const { onClose, selectedValue, isOpen, handleSubmit, destroy } = props;
    const [parentCategoryOptions, setParentCategoryOptions] = useState([]);
    const [categoryIconOptions, setCategoryIconOptions] = useState([]);
    let categoryIconType = 'normal';

    const { t } = useTranslation('common');

    useEffect(() => {
        props.initialize({
            parentCategory: null,
            categoryName: null,
            categoryIconSearch: 'app',
            categoryIcon: null,
        });

        let newList = [];
        for (const icon of icons.normal) {
            newList.push(icon);
        }
        setCategoryIconOptions(newList);

        return () => {
            categorySearch.cancel();
        };
    }, []);

    useEffect(() => {
        let tempParentCategoryOptions = [];
        props.categories.forEach((category) => {
            tempParentCategoryOptions.push({
                id: category.categoryId,
                label: category.categoryName,
                Component: <ImageIcon />,
            });
        });
        setParentCategoryOptions(tempParentCategoryOptions);
    }, [props.categories]);

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To handle when the popup is being closed, and it will call the parent method
   * -----------------------------------------------------------------------------------------------------------------
   */
    const handleClose = () => {
        onClose(selectedValue);
        destroy();
    };

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To handle when category add button clicked,
   * Here the api will hit to create the category on backend side and
   * on success, it will update the local redux list of category
   * -----------------------------------------------------------------------------------------------------------------
   * @param values
   * @returns {*}
   */
    const onCategoryAdd = (values) => {
        let name = values?.categoryName;
        let parentCategoryId = values.parentCategory?.id;
        let categoryIcon = values.categoryIcon?.value;

        return props
            .createCategory(name, parentCategoryId, categoryIcon)
            .then((response) => {
                props.pushCategory(response.data.data);
                props.onClose();
            })
            .catch((error) => {
                throw new SubmissionError(error?.response?.data?.errors);
            });
    };

    const handleCategorySearch = (value) => {
        let newList = value
            ? icons[categoryIconType].filter((icon) => {
                return icon.label.toLowerCase().includes(value.toLowerCase());
            })
            : [];
        setCategoryIconOptions(newList);
    };

    const categorySearch = useMemo(() => {
        return _.debounce(handleCategorySearch, 300);
    }, []);

    const onCategoryIconSearch = (event, value) => {
        categorySearch(value);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={isOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>{t('add category')}</DialogTitle>
            <DialogContent>
                <Grid container justifyContenet={'center'} alignItems={'center'}>
                    <Form
                        onSubmit={handleSubmit(onCategoryAdd)}
                        style={{ width: '100%' }}
                    >
                        <Field
                            name="categoryName"
                            placeholder={t('category name')}
                            icon={<Category />}
                            component={TextInput}
                        />
                        <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                            <Field
                                name="parentCategory"
                                placeholder={t('parent category')}
                                icon={<WorkspacesIcon />}
                                component={AutoCompleteSelect}
                                options={parentCategoryOptions}
                                renderOption={(props, { label, Component }) => {
                                    return (
                                        <li {...props}>
                                            <Grid container alignItems={'center'} sx={{ padding: 1 }}>
                                                {Component}{' '}
                                                <span style={{ marginLeft: 10 }}>{label}</span>
                                            </Grid>
                                        </li>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid container>
                            <Field
                                name={'categoryIcon'}
                                placeholder={t('category icon')}
                                component={AutoCompleteSelect}
                                onInputChange={onCategoryIconSearch}
                                options={categoryIconOptions}
                                icon={<DesktopMacIcon />}
                                renderOption={(props, { label, Component }) => {
                                    return (
                                        <li {...props}>
                                            <Grid container alignItems={'center'} sx={{ padding: 1 }}>
                                                <Component />{' '}
                                                <span style={{ marginLeft: 10 }}>{label}</span>
                                            </Grid>
                                        </li>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'contained'}
                                type={'submit'}
                                sx={{ marginTop: '20px' }}
                            >
                                {t('create category')}
                            </Button>
                        </Grid>
                    </Form>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

CategoryAddDialog = reduxForm({
    form: 'categoryAddForm',
    validate: validationService.categoryAddValidations,
})(CategoryAddDialog);

const mapStateToProps = (state) => {
    return {
        categories: state.categoryReducer.categories,
    };
};

const mapDispatchToProps = {
    createCategory: createCategories,
    pushCategory: pushCategory,
};

CategoryAddDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CategoryAddDialog);

export default CategoryAddDialog;
