import { get, post } from './baseApiService';
import apiRoutes from '../../config/apiRoutes';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To get the categories data by the api
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @returns {function(): Promise}
 */
export const getCategories = () => {
    return () => {
        return get(apiRoutes.getCategories);
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To create a new category
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} categoryName
 * @param {String|null} parentCatId
 * @param categoryIcon
 * @returns {function(): Promise}
 */
export const createCategories = (
    categoryName,
    parentCatId,
    categoryIcon = undefined,
) => {
    return () => {
        return post(apiRoutes.createCategory, {
            categoryName,
            parentCategoryId: parentCatId,
            categoryIcon: categoryIcon,
        });
    };
};
