import { post } from './baseApiService';
import apiRoutes from '../../config/apiRoutes';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To call the login api with provided data
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} username
 * @param {String} password
 * @returns {Promise}
 */
export const login = (username, password) => {
    return () => {
        return post(apiRoutes.login, { username, password });
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To call the signup api with provided data
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} fullName
 * @param {String} email
 * @param {String} password
 * @returns
 */
export const signup = (fullName, email, password) => {
    return () => {
        return post(apiRoutes.signup, { fullName, email, password });
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To hit the logout api and revoke the token used
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @returns {Function}
 */
export const logout = () => {
    return () => {
        return post(apiRoutes.logout);
    };
};
