import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom/dist';
import CommonLoader from '../../../components/loadings/commonLoader';
import { connect } from 'react-redux';
import { getGroup } from '../../../services/apiServices/groupApiService';
import { Grid, Paper } from '@mui/material';
import { apiErrorHandler } from '../../../services/notificationService';
import GroupDetailNavigation from './groupDetails/groupNavigation';
import GroupDashboard from './groupDetails/groupDashboard';
import GroupUserList from './groupDetails/groupUserList';
import { updateCurrentGroup } from '../../../redux/actions/groupActions';
import GroupBills from './groupDetails/groupBills/groupBills';
import GroupAnalyser from './groupDetails/groupAnalyser/groupAnalyser';

const NAVIGATORS = {
    DASHBOARD: 'dashboard',
    USERS: 'users',
    BILLS: 'bills',
    ANALYSER: 'analyser',
};

let GroupDetail = (props) => {
    const { groupId, nav } = useParams();
    const [loader, setLoader] = useState(true);
    const { groupDetails } = props;
    const navigate = useNavigate();
    const [currentNavigation, setCurrentNavigation] = useState(null);

    useEffect(() => {
        props
            .getGroup(groupId)
            .then((res) => {
                if (res.status === 200) {
                    props.setCurrentGroup(res.data.data);
                    setLoader(false);
                }
            })
            .catch((error) => {
                apiErrorHandler(error);
            });
    }, []);

    useEffect(() => {
        let currentNav = nav;
        if (!currentNav) {
            currentNav = NAVIGATORS.DASHBOARD;
            navigate(`/group/${groupId}/${currentNav}`);
        }
        setCurrentNavigation(currentNav);
    }, [nav]);

    if (loader || !groupDetails) {
        return (
            <Grid container justifyContent={'center'} sx={{ marginTop: 50 }}>
                <Grid item lg={3}>
                    <CommonLoader />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={3} sx={{ padding: 3 }}>
            <Grid item lg={2}>
                <GroupDetailNavigation
                    currentNavigation={currentNavigation}
                    groupDetails={groupDetails}
                />
            </Grid>
            <Grid item lg={10}>
                <Paper elevation={0}>
                    {currentNavigation === NAVIGATORS.DASHBOARD && <GroupDashboard />}
                    {currentNavigation === NAVIGATORS.USERS && (
                        <GroupUserList groupUsers={groupDetails.users} />
                    )}
                    {currentNavigation === NAVIGATORS.BILLS && <GroupBills />}
                    {currentNavigation === NAVIGATORS.ANALYSER && <GroupAnalyser />}
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        groupDetails: state.groupReducer.currentGroup,
    };
};

const mapDispatchToProps = {
    // API Calls
    getGroup: getGroup,

    // State Calls
    setCurrentGroup: updateCurrentGroup,
};

GroupDetail = connect(mapStateToProps, mapDispatchToProps)(GroupDetail);

export default GroupDetail;
