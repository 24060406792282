import actionTypes from '../types';

let initialState = {
    groups: [],
    currentGroup: null,
    bills: [],
};

let groupReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GROUP.UPDATE_GROUP_LIST:
        state = {
            ...state,
            groups: action.payload,
        };
        break;
    case actionTypes.GROUP.UPDATE_CURRENT_GROUP:
        state = {
            ...state,
            currentGroup: action.payload,
        };
        break;
    case actionTypes.GROUP.ADD_USER_CURRENT_GROUP: {
        let isExists = state.currentGroup?.users?.find((u) => {
            return u.userId === action.payload.userId;
        });
        let users = state?.currentGroup?.users
            ? [...state.currentGroup.users]
            : [];
        if (!isExists && state.currentGroup) {
            users.push(action.payload);
        }
        state = {
            ...state,
            currentGroup: {
                ...state.currentGroup,
                users,
            },
        };
        break;
    }
    case actionTypes.GROUP.REMOVE_USER_CURRENT_GROUP:
        state = {
            ...state,
            currentGroup: {
                ...state.currentGroup,
                users: state.currentGroup?.users.filter((u) => {
                    return u.userId !== action.payload;
                }),
            },
        };
        break;
    case actionTypes.GROUP.UPDATE_GROUP_BILLS:
        state = {
            ...state,
            bills: action.payload,
        };
        break;
    case actionTypes.GROUP.ADD_GROUP_BILLS:
        state = {
            ...state,
            bills: [action.payload, ...state.bills],
        };
        break;
    case actionTypes.GROUP.REMOVE_GROUP_BILLS:
        state = {
            ...state,
            bills: state.bills.filter((b) => {
                return b.billId !== action.payload;
            }),
        };
        break;
    default:
        return state;
    }
    return state;
};

export default groupReducer;
