import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getGroupDetails } from '../../../../services/apiServices/groupApiService';
import { connect } from 'react-redux';

let GroupDashboard = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <Typography variant="h4">{t('dashboard')}</Typography>
        </>
    );
};

const mapDispatchToProps = {
    getGroupDetails: getGroupDetails,
};

GroupDashboard = connect(null, mapDispatchToProps)(GroupDashboard);

export default GroupDashboard;
