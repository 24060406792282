import React from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './App.css';
import './Common.css';
import AuthRoutes from './routes/authRoutes';
import { updateSystemTheme, updateTheme } from './redux/actions/appActions';
import CommonSnackbar from './components/snackbars/commonSnackbar';

let themes = {
    dark: createTheme({
        palette: {
            mode: 'dark',
        },
    }),
    light: createTheme({
        palette: {
            mode: 'light',
        },
    }),
};

let eventStarted = false;

function App(props) {
    const [currentTheme, setCurrentTheme] = useState('light');

    // here first system theme will be fetched and store to redux, and a event listener will be started
    useEffect(() => {
        if (!eventStarted) {
            eventStarted = true;
            let systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
                ?.matches
                ? 'dark'
                : 'light';
            // updating the system theme
            props.updateSystemTheme(systemTheme);

            // starting the listener to handle the system theme update
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .addEventListener('change', (event) => {
                    const colorScheme = event.matches ? 'dark' : 'light';
                    props.updateSystemTheme(colorScheme);
                });
        }

        let savedTheme = localStorage.getItem('currentTheme') || 'system';
        props.updateTheme(savedTheme);
    }, []);

    // to handle when the application theme redux state update , it will apply the updated theme
    // to theme provider so the new updated theme gets in effect
    useEffect(() => {
        if (props.currentTheme === 'system') {
            setCurrentThemeAsSystem();
        } else {
            setCurrentTheme(props.currentTheme);
        }
    }, [props.currentTheme]);

    // to update the application theme when user system theme is updated, it will check if user have
    // selected the same as system in current theme
    useEffect(() => {
        setCurrentThemeAsSystem();
    }, [props.systemTheme]);

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To get the current theme selected by the system
   * -----------------------------------------------------------------------------------------------------------------
   *
   * @returns {String}
   */
    const getSystemTheme = () => {
        return window.matchMedia('(prefers-color-scheme: dark)')?.matches
            ? 'dark'
            : 'light';
    };

    /**
   * -----------------------------------------------------------------------------------------------------------------
   * @description To apply the system them in application if the user has selected the Same as System theme option
   * -----------------------------------------------------------------------------------------------------------------
   */
    const setCurrentThemeAsSystem = () => {
        if (props.currentTheme === 'system') {
            setCurrentTheme(getSystemTheme());
        }
    };

    return (
        <ThemeProvider theme={themes[currentTheme || 'light']}>
            <CssBaseline />
            <CommonSnackbar />
            <AuthRoutes />
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        currentTheme: state?.appReducer?.theme?.mode,
        systemTheme: state?.appReducer?.theme?.systemMode,
    };
};

const mapDispatchToProps = {
    updateSystemTheme: updateSystemTheme,
    updateTheme: updateTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
