/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To get the user data from the local storage and convert it to object
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @returns {Object}
 */
export const getUserData = () => {
    let userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null;
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To get the first letter of user's first name and last name
 * if there is only first name single character of first name will be returned
 *
 * @note if there is middle name then the return will be first letter of first name and first letter of middle name
 * example : First Middle Last
 * return :FM
 *
 * example: 2: First Last
 * return :FL
 *
 * example: 3: First
 * return :F
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @returns {String}
 */
export const getUserNameShort = () => {
    let userData = getUserData();
    if (userData.fullName) {
        let split = userData.fullName.split(' ');
        // checking if there are two words to split
        if (split.length > 1) {
            return `${split[0][0]}${split[1][0]}`;
        } else {
            return split[0];
        }
    }
    return '';
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To get the user token from the local storage
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @returns {String|null}
 */
export const getToken = () => {
    return localStorage.getItem('authToken');
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To remove the token from the local storage
 * ---------------------------------------------------------------------------------------------------------------------
 */
export const removeToken = () => {
    localStorage.removeItem('authToken');
};
