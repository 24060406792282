import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    deleteBill,
    getBills,
} from '../../../../services/apiServices/billApiService';
import { connect } from 'react-redux';
import { Chip, Divider, Grid, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    removeGroupBills,
    updateGroupBills,
} from '../../../../redux/actions/groupActions';
import moment from 'moment';
import { Delete } from '@mui/icons-material';
import { apiErrorHandler } from '../../../../services/notificationService';
import AddBillDialog from '../../groups/groupDetails/groupBills/addBillDialog';

let BillList = (props) => {
    const { t } = useTranslation('common');
    const [billFormData, setBillFormData] = useState(null);
    // const [selectedBills, setSelectedBills] = useState([]);

    const deleteBill = (billId) => {
        return () => {
            props
                .deleteBill(billId)
                .then(() => {
                    props.removeBill(billId);
                })
                .catch((e) => {
                    apiErrorHandler(e);
                });
        };
    };

    const handleBillFormClose = () => {
        setBillFormData(null);
    };

    // const handleBillSelect = (billId) => (event) => {
    //     event.stopPropagation();
    //     let newSelectedBills = selectedBills.filter(id => id !== billId);
    //     if (event.target.checked) { // the bill is selected now
    //         newSelectedBills.push(billId);
    //     }
    //     setSelectedBills(newSelectedBills);
    // }

    return (
        <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
            <Grid item lg={12} md={6}>
                <AddBillDialog
                    open={billFormData}
                    bill={billFormData}
                    handleClose={handleBillFormClose}
                />
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                    {t('total_bills')}: {props.billList.length}
                </Typography>
                {props.billList.map((bill) => {
                    return (
                        <Accordion key={`bill-accordion-${bill.billId}`}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid
                                    container
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Grid item lg={6}>
                                        <Typography>{bill.title}</Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            {t('participants')} - {bill.participants.length}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={2}
                                        container
                                        justifyContent={'start'}
                                        alignItems={'start'}
                                    >
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            {moment(bill.date).format('ll')}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} container justifyContent={'end'}>
                                        <Typography>₹ {bill.amount}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container alignItems={'center'}>
                                    <Grid item lg={10}>
                                        <Typography component={'h1'} color={'text.secondary'}>
                                            {bill.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={1} container justifyContent={'end'}>
                                        {/* <IconButton onClick={() => setBillFormData(bill)}>
                                                <Edit/>
                                            </IconButton> */}
                                    </Grid>
                                    <Grid item lg={1} container justifyContent={'end'}>
                                        <IconButton onClick={deleteBill(bill.billId)}>
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 1, mb: 2 }} />
                                <Grid container spacing={2}>
                                    <Grid item lg={2} container direction={'row'}>
                                        <Grid item lg={11}>
                                            <Typography component={'h1'} sx={{ mb: 2 }}>
                                                {t('paid by')}
                                            </Typography>
                                            <Tooltip
                                                key={`participant-chip-${bill.paidBy.id}`}
                                                title={bill.paidBy.name ? bill.paidBy.email : ''}
                                            >
                                                <Chip
                                                    sx={{ mr: 1 }}
                                                    label={bill.paidBy.name || bill.paidBy.email}
                                                    color={'success'}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item container lg={1}>
                                            <Divider orientation="vertical" flexItem />
                                        </Grid>
                                    </Grid>
                                    <Grid item container lg={10}>
                                        <Grid item lg={12}>
                                            <Typography component={'h1'} sx={{ mb: 2 }}>
                                                {t('split with')}
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            {bill.participants.map((participant) => {
                                                return (
                                                    <Tooltip
                                                        key={`participant-chip-${participant.id}`}
                                                        title={participant.name ? participant.email : ''}
                                                    >
                                                        <Chip
                                                            sx={{ mr: 1, mb: 1 }}
                                                            label={participant.name || participant.email}
                                                            color={'default'}
                                                        />
                                                    </Tooltip>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = {
    getBills: getBills,
    updateGroupBills: updateGroupBills,
    removeBill: removeGroupBills,
    deleteBill: deleteBill,
};

const mapStateToProps = (state) => {
    return {
        billList: state.groupReducer.bills,
    };
};

BillList = connect(mapStateToProps, mapDispatchToProps)(BillList);

export default BillList;
