const APP = {
    APP_THEME_UPDATE: 'APP_THEME_UPDATE',
    SYSTEM_THEME_UPDATE: 'SYSTEM_THEME_UPDATE',
    UPDATE_AUTH_USER: 'UPDATE_AUTH_USER',
};

const CATEGORY = {
    UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
    PUSH_CATEGORY: 'PUSH_CATEGORY',
};

const GROUP = {
    UPDATE_GROUP_LIST: 'UPDATE_GROUP_LIST',
    UPDATE_CURRENT_GROUP: 'UPDATE_CURRENT_GROUP',
    ADD_USER_CURRENT_GROUP: 'ADD_USER_CURRENT_GROUP',
    REMOVE_USER_CURRENT_GROUP: 'REMOVE_USER_CURRENT_GROUP',
    UPDATE_GROUP_BILLS: 'UPDATE_GROUP_BILLS',
    ADD_GROUP_BILLS: 'ADD_GROUP_BILLS',
    REMOVE_GROUP_BILLS: 'REMOVE_GROUP_BILLS',
};

let actionTypes = {
    APP,
    CATEGORY,
    GROUP,
};

export default actionTypes;
