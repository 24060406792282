export const mapCategoriesWithChild = (categories) => {
    let categoryWithCount = {};

    categories.forEach((category) => {
        if (category.parentCategoryId) {
            categoryWithCount[category.parentCategoryId] = categoryWithCount[
                category.parentCategoryId
            ]
                ? categoryWithCount[category.parentCategoryId] + 1
                : 1;
        }
    });

    return categories.map((category) => {
        return {
            ...category,
            childCount: categoryWithCount[category.categoryId] || 0,
        };
    });
};
