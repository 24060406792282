import { Grid, IconButton } from '@mui/material';
import React from 'react';
import TextInput from '../../../../formFields/TextInput';
import { Field } from 'redux-form';
import { Add, Remove } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

let GroupUserArray = (props) => {
    const { fields } = props;
    const { t } = useTranslation('common');

    const addField = () => {
        fields.push({});
    };

    return (
        <>
            {fields.map((member, index) => {
                return (
                    <Grid
                        container
                        justifyContent={'center'}
                        key={`groups-user-array-${index}`}
                    >
                        <Grid item lg={10}>
                            <Field
                                name={`${member}.email`}
                                type={'text'}
                                component={TextInput}
                                variant="filled"
                                placeholder={t('username')}
                                icon={props.icon}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={2}
                            container
                            justifyContent={'end'}
                            alignItems={'center'}
                        >
                            <Grid item lg={6}>
                                {index === fields.length - 1 && (
                                    <IconButton
                                        disabled={!fields.get(index).email}
                                        onClick={addField}
                                    >
                                        <Add />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item lg={6}>
                                {fields.length > 1 && (
                                    <IconButton
                                        onClick={() => {
                                            return fields.remove(index);
                                        }}
                                        aria-label="add-group"
                                    >
                                        <Remove />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default GroupUserArray;
