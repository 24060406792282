import { get } from './baseApiService';
import apiRoutes from '../../config/apiRoutes';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To get the user data by hitting the get user api with token
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @returns {Promise}
 */
export const getUser = () => {
    return () => {
        return get(apiRoutes.getUser);
    };
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To search the user with the provided search key
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {String} searchKey
 * @returns {Promise}
 */
export const searchUser = (searchKey) => {
    return () => {
        return get(`${apiRoutes.searchUser}?key=${searchKey}`);
    };
};
