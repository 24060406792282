import React, { useState } from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import CategoryAddDialog from '../../views/categories/categoryAddDialog/categoryAddDialog';
import CategoryIcon from '@mui/icons-material/Category';
import { useTranslation } from 'react-i18next';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

let AddSpeedDial = () => {
    const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false);
    const { t } = useTranslation('common');

    const actions = [
        {
            icon: <CategoryIcon />,
            name: t('category'),
            onClick: () => {
                return setAddCategoryDialogOpen(true);
            },
        },
        { icon: <ReceiptLongIcon />, name: t('transaction') },
    ];

    return (
        <>
            <CategoryAddDialog
                isOpen={addCategoryDialogOpen}
                onClose={() => {
                    return setAddCategoryDialogOpen(false);
                }}
            />
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => {
                    return (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={action.onClick}
                        />
                    );
                })}
            </SpeedDial>
        </>
    );
};

export default AddSpeedDial;
