/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To validate the login form on front end side
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param {Object} values
 * @returns
 */
const loginFormValidations = (values) => {
    const errors = {};
    if (!values.username) {
        errors.username = 'Required';
    }
    return errors;
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description To validate the category create form
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param values
 * @returns {{}}
 */
const categoryAddValidations = (values) => {
    const errors = {};
    if (!values.categoryName) {
        errors['categoryName'] = 'Required';
    }
    return errors;
};

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * @description Constant validations for reusability
 * These validation fields can be used to validate the data
 * ---------------------------------------------------------------------------------------------------------------------
 */
let formValidations = {
    required: (value) => {
        return value ? undefined : 'Required';
    },
    maxLength: (max) => {
        return (value) => {
            return value && value.length > max
                ? `Must be ${max} characters or less`
                : undefined;
        };
    },
    minLength: (min) => {
        return (value) => {
            return value && value.length < min
                ? `Must be at least ${min} characters`
                : undefined;
        };
    },
    email: (value) => {
        return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? 'Invalid email address'
            : undefined;
    },
    passwordMustMatch: (value, allValues) => {
        return value && allValues.password && value !== allValues.password
            ? 'Password must be same'
            : undefined;
    },
};

const fieldValidation = {
    required: (value) => {
        return value ? undefined : 'Required';
    },
};

let validationService = {
    loginFormValidations: loginFormValidations,
    categoryAddValidations: categoryAddValidations,
    formValidations: formValidations,
    fieldValidation: fieldValidation,
};

export default validationService;
