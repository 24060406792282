import { Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './404.css';

let checkOnce = false;

const Code404 = () => {
    let [message, setMessage] = useState('');
    let [messageCode, setMessageCode] = useState('');
    let currentMessageCode = useRef('');
    let currentMessage = useRef('');

    let fullMessageCode = '404';
    let fullMessage = 'Not Found ..';

    let currentIndex = useRef(0);
    let currentMessageIndex = useRef(0);

    useEffect(() => {
        if (!checkOnce) {
            checkOnce = true;
            setInterval(() => {
                if (fullMessage !== currentMessage.current) {
                    if (fullMessageCode !== currentMessageCode.current) {
                        currentMessageCode.current +=
              fullMessageCode[currentIndex.current++];
                        setMessageCode(currentMessageCode.current);
                    } else {
                        currentMessage.current +=
              fullMessage[currentMessageIndex.current++];
                        setMessage(currentMessage.current);
                    }
                }
            }, 400);
        }
    }, []);

    return (
        <div className="errorCodeContainer">
            <Grid
                container
                height={'100%'}
                justifyContent={'center'}
                alignItems="center"
            >
                <div className="code" style={{ textAlign: 'center' }}>
                    <span className="fn-3">{messageCode}</span>
                    <br />
                    {message}
                </div>
            </Grid>
        </div>
    );
};

export default Code404;
