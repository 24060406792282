import React from 'react';
import { Button, Grid } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import DateInput from '../../../../../formFields/DateInput';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@mui/icons-material';
import { useEffect } from 'react';
import moment from 'moment';
import validationService from '../../../../../services/validationService';

let AnalyzerDateRange = (props) => {
    const { handleSubmit, initialize } = props;
    const { t } = useTranslation('common');

    useEffect(() => {
        initialize({
            startDate: moment().subtract(1, 'Y').valueOf(),
            endDate: moment().valueOf(),
        });
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <Grid
                    container
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <Field
                            name="startDate"
                            component={DateInput}
                            placeholder={t('start date')}
                            icon={<></>}
                            validate={[validationService.fieldValidation.required]}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <ArrowForward />
                    </Grid>
                    <Grid item>
                        <Field
                            name="endDate"
                            component={DateInput}
                            placeholder={t('end date')}
                            icon={<></>}
                            validate={[validationService.fieldValidation.required]}
                        />
                    </Grid>
                    <Grid item sx={{ ml: 5 }}>
                        <Button variant={'contained'} type="submit">
                            {t('calculate spent')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

AnalyzerDateRange = reduxForm({
    form: 'groupAnalyser',
})(AnalyzerDateRange);

export default AnalyzerDateRange;
