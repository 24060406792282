import {
    Avatar,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AuthHeader from '../../../components/headers/authHeader/authHeader';
import { getTransactions } from '../../../services/apiServices/transactionService';
import moment from 'moment';
import './transactionList.css';
import { ToWords } from 'to-words';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { apiErrorHandler } from '../../../services/notificationService';

let TransactionList = (props) => {
    let [transactionsList, setTransactionsList] = useState([]);
    let { t } = useTranslation('common');

    let toWords = new ToWords({
        localeCode: `${i18next.language}-IN`,
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
                // can be used to override defaults for the selected locale
                name: t('rupee'),
                plural: t('rupees'),
                symbol: '₹',
                fractionalUnit: {
                    name: 'Paisa',
                    plural: 'Paise',
                    symbol: '',
                },
            },
        },
    });

    useEffect(() => {
        props
            .getTransactions()
            .then((response) => {
                if (response.data.data) {
                    setTransactionsList(response.data.data);
                }
            })
            .catch((error) => {
                apiErrorHandler(error);
            });
    }, []);

    return (
        <>
            <AuthHeader currentTab={'transaction'} />
            <Grid container justifyContent={'center'} marginTop={'20px'}>
                <List
                    sx={{ width: '100%', maxWidth: '50%', bgcolor: 'background.paper' }}
                >
                    {transactionsList.map((transaction) => {
                        return (
                            <>
                                <ListItem
                                    key={transaction.transactionId}
                                    alignItems="flex-start"
                                    className={`transListRow${transaction.transactionType === 'D' ? 'Red' : 'Green'}`}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src="/static/images/avatar/1.jpg"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="h6"
                                                    color={
                                                        transaction.transactionType === 'D'
                                                            ? 'Red'
                                                            : 'Green'
                                                    }
                                                >
                                                    {`₹ ${transaction.amount} `}
                                                </Typography>
                                                {` (${toWords.convert(transaction.amount)})`}
                                            </>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <ListItemText
                                                    primary={moment(transaction.transactionTime).format(
                                                        'LLL',
                                                    )}
                                                    sx={
                                                        {
                                                            // color: transaction.transactionType === 'D' ? 'red' : 'green',
                                                            // fontWeight: "bold",
                                                        }
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline', fontWeight: 'bold' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                {transaction.category.categoryName}
                                                            </Typography>
                                                            {transaction.comment
                                                                ? ` - ${transaction.comment}`
                                                                : ''}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </>
                        );
                    })}
                </List>
            </Grid>
        </>
    );
};

let mapStateToProps = null;
let mapDispatchToProps = {
    getTransactions: getTransactions,
};

TransactionList = connect(mapStateToProps, mapDispatchToProps)(TransactionList);

export default TransactionList;
