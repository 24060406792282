import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddBillDialog from './addBillDialog';
import BillList from '../../../bills/billList/billList';
import { updateGroupBills } from '../../../../../redux/actions/groupActions';
import { getBills } from '../../../../../services/apiServices/billApiService';
import { connect } from 'react-redux';
import { apiErrorHandler } from '../../../../../services/notificationService';
import { useParams } from 'react-router-dom';

const GroupBills = (props) => {
    const { t } = useTranslation('common');
    const [addBillVisible, setAddBillVisible] = useState(null);
    const [loader, setLoader] = useState(true);
    const { groupId } = useParams();

    useEffect(() => {
        props.getBills({ groupId }).then((res) => {
            props.updateGroupBills(res.data.data);
            setLoader(false);
        });
    }, []);

    const handleShowUserFilter = (event) => {
        setLoader(true);
        props
            .getBills({
                groupId: groupId,
                showUserOnly: event.target.checked ? 1 : 0,
            })
            .then((res) => {
                props.updateGroupBills(res.data.data);
                setLoader(false);
            })
            .catch((e) => {
                apiErrorHandler(e);
                props.updateGroupBills([]);
                setLoader(false);
            });
    };

    return (
        <>
            <Grid container justifyContent={'space-between'}>
                <Typography variant="h5">{t('bills')}</Typography>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={t('show my bills only')}
                        onChange={handleShowUserFilter}
                        disabled={loader}
                    />
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            return setAddBillVisible(true);
                        }}
                    >
                        {t('add bill')}
                    </Button>
                </Grid>
            </Grid>
            {loader ? (
                <Grid container justifyContent={'center'} sx={{ mt: 5, mb: 5 }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <BillList />
            )}
            <AddBillDialog
                open={addBillVisible}
                handleClose={() => {
                    return setAddBillVisible(false);
                }}
            />
        </>
    );
};

const mapDispatchToProps = {
    getBills: getBills,
    updateGroupBills: updateGroupBills,
};

export default connect(null, mapDispatchToProps)(GroupBills);
