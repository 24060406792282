import React, { useState } from 'react';
import { getCategories } from '../../../services/apiServices/categoriesApiService';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';

let CategoryItem = (props) => {
    const { category } = props;
    const [actionButtonVisible, setActionButtonVisible] = useState(false);
    const { t } = useTranslation('common');

    return (
        <ListItem
            onFocus={() => {
                setActionButtonVisible(true);
            }}
            onMouseEnter={() => {
                setActionButtonVisible(true);
            }}
            onMouseLeave={() => {
                setActionButtonVisible(false);
            }}
            secondaryAction={
                <>
                    {actionButtonVisible && (
                        <>
                            <IconButton hidden={false}>
                                <EditIcon hidden={true} />
                            </IconButton>
                            <IconButton hidden={actionButtonVisible}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                    {category.childCount && !props.disableNestedSelect ? (
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                                return props.onSubClick(category);
                            }}
                        >
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </>
            }
        >
            <ListItemAvatar>
                <Avatar>
                    <ImageIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                sx={{ textTransform: 'capitalize' }}
                primary={category.categoryName}
                secondary={
                    category.childCount
                        ? `${category.childCount} ${t('sub categories')}`
                        : ''
                }
            />
        </ListItem>
    );
};

const mapDispatchToProps = {
    getCategories: getCategories,
};

CategoryItem = connect(null, mapDispatchToProps)(CategoryItem);

export default CategoryItem;
