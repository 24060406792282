import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './routeMiddlewares/ProtectedRoutes';
import Dashboard from '../views/dashboard/dashboard';
import Login from '../views/authenticate/login/login';
import Code404 from '../views/errors/404';
import TransactionList from '../views/transacations/transactionList/transactionList';
import CategoriesList from '../views/categories/categoriesList/categoriesList';
import TallyDashboard from '../views/tally/tallyDashboard/tallyDashboard';
import GroupCreate from '../views/tally/groups/groupCreate/groupCreate';
import GroupDetail from '../views/tally/groups';
import MemberAnalyze from '../views/tally/groups/groupDetails/groupAnalyser/memberAnalyzer/memberAnalyze';
import { APP_TABS } from '../constants';

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Login />} />
            <Route path={'/login'} element={<Login />} />
            <Route path={'/dashboard'} element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
            <Route path={'/transactions'} element={<ProtectedRoutes tabValue={APP_TABS.TRANSACTIONS}> <TransactionList /> </ProtectedRoutes>} />
            <Route path={'/categories'} element={<ProtectedRoutes tabValue={APP_TABS.CATEGORIES}> <CategoriesList /> </ProtectedRoutes>} />
            <Route path={'/groups'} element={<ProtectedRoutes tabValue={APP_TABS.GROUPS}> <TallyDashboard /> </ProtectedRoutes>} />
            <Route path={'/group/create'} element={<ProtectedRoutes> <GroupCreate /> </ProtectedRoutes>} />
            <Route path={'/group/:groupId/:nav?'} element={<ProtectedRoutes> <GroupDetail /> </ProtectedRoutes>} />
            <Route path={'/group/:groupId/analyser/member/:memberId'} element={<ProtectedRoutes> <MemberAnalyze /> </ProtectedRoutes>} />
            <Route path={'*'} element={<ProtectedRoutes> <Code404 /> </ProtectedRoutes>}
            />
        </Routes>
    );
};

export default AuthRoutes;
